
import AdditionalOrbitModalHeader from './AdditionalOrbitModalHeader'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { Fragment, useEffect, useState } from 'react';
import { capitalizeFirstLetter, containsSpecialChars } from '../../../../../../CommonComponents/CommonFunctions';
import { useParams } from 'react-router-dom';
import MissionService from '../../../../../../../service/MissionService';
import { scenario_visualization } from '../../scenario_visualization/DataSources';
import useScenarioViewer from '../../hooks/useScenarioViewer';
dayjs.extend(utc)

interface AdditionalOrbitDetailsProps {
    additional_orbits: any[];
    set_scenario_additional_orbits: (details: any) => void;
    additional_orbit_details: any;
    set_scenario_additional_orbits_details: (details: any) => void;
    close: () => void;
    open: any;
    scenario_id: any;
    scenario_name: any;
    setAdditionalOrbits: (data: boolean) => void;
    additionalOrbit: boolean;
    set_loading: (loading: boolean | string) => void;
}

const AdditionalOrbitDetailsModal: React.FC<AdditionalOrbitDetailsProps> = ({ additional_orbits, set_scenario_additional_orbits, additional_orbit_details, set_scenario_additional_orbits_details, close, scenario_id, scenario_name, open, setAdditionalOrbits, additionalOrbit, set_loading }) => {

    const params = useParams();
    const {
        scenario_step,
        truetwin_id
    } = params;

    const { viewer } = useScenarioViewer();
    const [edited_orbit_details, set_edited_orbit_details] = React.useState<any>({})
    const [showBody, setShowBody] = useState(true)

    const check_validation = () => {
        const orbitParams = additional_orbit_details
        const not_validated = Object.keys(additional_orbit_details).some(key => {
            if (key === 'altitude') {
                if ((orbitParams?.[key] === '') || +orbitParams?.[key] < 300 || +orbitParams?.[key] > 750) {
                    toast.error(orbitParams?.[key] === '' ? capitalizeFirstLetter(key) + " is required" : capitalizeFirstLetter(key) + " should be between 300 and 750 kms", { toastId: "o" })
                    return true;
                }
            } else {
                if (orbitParams?.[key] === '') {
                    toast.error(capitalizeFirstLetter(key) + " is required", { toastId: "o" })
                    return true;
                }
            }
        });

        if (not_validated) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        if (open === 'edit') {
            set_edited_orbit_details(additional_orbit_details)
        }
    }, [open])


    const post_external_orbits = (additional_orbit_data) => {
        set_loading('Computing')
        MissionService.addExperimentalObjects(scenario_id, additional_orbit_data)?.then((res) => {
            if (res?.data) {
                // get_scenario_data(scenarioId)
                // setScenarioScheduled(true)
                // setDataSubmited([...dataSubmited, 'Additional_Orbits'])
                // setScenarioScheduleData([])
                // navigate_scenario('Ground_Station', scenarioName, scenarioId)
                setAdditionalOrbits(!additionalOrbit)
                scenario_visualization().init_satellite_packet(viewer, scenario_id, truetwin_id)
            }
        })?.catch((err) => {
            toast.error("Getting error while posting additional orbits.", { toastId: '0' });
        }).finally(() => {
            set_loading(false)
        })
    }



    const submit_additional_orbit = () => {
        const validated = check_validation()
        if (validated) {
            let filter_orbits = additional_orbits?.filter((orbit) => orbit?.id !== edited_orbit_details?.id)
            let additional_orbit_list = open === 'edit' ? filter_orbits : additional_orbits

            let additional_orbit_data = {
                name: additional_orbit_details?.name,
                orbitParameters: {
                    altitude: additional_orbit_details?.altitude,
                    inclination: additional_orbit_details?.inclination,
                    eccentricity: additional_orbit_details?.eccentricity,
                    RAAN: additional_orbit_details?.RAAN,
                    AP: additional_orbit_details?.AP,
                    TA: additional_orbit_details?.TA
                }
            }

            post_external_orbits(additional_orbit_data)

            set_scenario_additional_orbits([...additional_orbit_list, additional_orbit_data])
            set_edited_orbit_details({})
            close()
        }
    }
    return (
        <div className={(open && scenario_step === 'Additional_Orbits') ? 'additional__orbit__details__modal' : 'd-none'}>
            <AdditionalOrbitModalHeader toggle_body={() => { setShowBody(!showBody) }} close={close} />
            {showBody &&
                <Fragment>
                    <div className='py-3 additional__orbit__details__modal__container'>
                        {(open || additional_orbits?.length === 0) && (
                            <div className="referenceOrbitDiv">
                                <form id="orbitalDetails gap-1">

                                    <div className="w-100 d-flex flex-column gap-1 px-3">
                                        <div className="modalAnalysisText">Object Name *</div>
                                        <input
                                            value={additional_orbit_details?.name}
                                            onChange={(event) => {
                                                let isNotValidName = containsSpecialChars(event.target.value)
                                                if (isNotValidName) {
                                                    toast.error('Object name cannot contain special characters.', { toastId: 'o' })
                                                } else {
                                                    if (event.target.value.length > 100) {
                                                        toast.error('Object name cannot be greater than 100 characters. ', { toastId: 'o' })
                                                    } else {
                                                        set_scenario_additional_orbits_details({
                                                            name: event.target.value.trimStart()
                                                        })
                                                    }
                                                }
                                            }}
                                            className="modalInputFullLen w-100 ms-0"
                                            type="text"
                                            placeholder="Enter object name"
                                        />
                                    </div>

                                    <div className="modalAnalysisDiv modalAnalysisDivLong mt-3">
                                        <div className="modalAnalysisText">Altitude (km)</div>
                                        <input
                                            value={additional_orbit_details?.altitude}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 || e.keyCode === 69) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (!isNaN(+event.target.value) && event.target.value?.length <= 100) {
                                                    set_scenario_additional_orbits_details({
                                                        altitude: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                                    })
                                                }
                                            }}
                                            className="modalAnalysisInput"
                                            type="number"
                                            step='any'
                                            placeholder="km"
                                        />
                                    </div>

                                    <div className="modalAnalysisDiv modalAnalysisDivLong">
                                        <div className="modalAnalysisText">Eccentricity </div>
                                        <input
                                            value={additional_orbit_details?.eccentricity}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 || e.keyCode === 69) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (+event.target.value >= 0 && +event.target.value <= 1 && !isNaN(+event.target.value)) {
                                                    if (event.target.value?.length <= 8) {
                                                        set_scenario_additional_orbits_details({
                                                            eccentricity: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                                        })
                                                    }
                                                } else {
                                                    toast.error("Eccentricity must be between 0 and 1", { toastId: '0' })
                                                }
                                            }}
                                            className="modalAnalysisInput"
                                            type="number"
                                            step='any'
                                            placeholder="degree"
                                        />
                                    </div>


                                    <div className="modalAnalysisDiv modalAnalysisDivLong">
                                        <div className="modalAnalysisText">Inclination </div>
                                        <input
                                            value={additional_orbit_details?.inclination}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 || e.keyCode === 69) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (+event.target.value >= 0 && +event.target.value <= 180 && !isNaN(+event.target.value)) {
                                                    if (event.target.value?.length <= 8) {
                                                        set_scenario_additional_orbits_details({
                                                            inclination: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                                        })
                                                    }
                                                } else {
                                                    toast.error("Inclination must be between 0 and 180", { toastId: '0' })
                                                }
                                            }}
                                            className="modalAnalysisInput"
                                            type="number"
                                            step='any'
                                            placeholder="degree"
                                        />
                                    </div>

                                    <div className="modalAnalysisDiv modalAnalysisDivLong">
                                        <div className="modalAnalysisText">Longitude of Ascending Node </div>
                                        <input
                                            value={additional_orbit_details?.RAAN}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 || e.keyCode === 69) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (+event.target.value >= 0 && +event.target.value <= 360 && !isNaN(+event.target.value)) {
                                                    if (event.target.value?.length <= 8) {
                                                        set_scenario_additional_orbits_details({
                                                            RAAN: event.target.value !== '' ? Math.abs(+event.target.value) : ''
                                                        })
                                                    }
                                                } else {
                                                    toast.error("Longitude of Ascending Node must be between 0 and 360", { toastId: '0' })
                                                }
                                            }}
                                            className="modalAnalysisInput"
                                            type="number"
                                            step='any'
                                            placeholder="degree"
                                        />
                                    </div>

                                    <div className="modalAnalysisDiv modalAnalysisDivLong">
                                        <div className="modalAnalysisText">Argument of periapsis </div>
                                        <input
                                            value={additional_orbit_details?.AP}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 || e.keyCode === 69) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (+event.target.value >= 0 && +event.target.value <= 360 && !isNaN(+event.target.value)) {
                                                    if (event.target.value?.length <= 8) {
                                                        set_scenario_additional_orbits_details({
                                                            AP: event.target.value !== '' ? +event.target.value : ''
                                                        })
                                                    }
                                                } else {
                                                    toast.error("Argument of periapsis must be between 0 and 360", { toastId: '0' })
                                                }
                                            }}
                                            className="modalAnalysisInput"
                                            type="number"
                                            step='any'
                                            placeholder="degree"
                                        />
                                    </div>

                                    <div className="modalAnalysisDiv modalAnalysisDivLong">
                                        <div className="modalAnalysisText">True Anomaly </div>
                                        <input
                                            value={additional_orbit_details?.TA}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 || e.keyCode === 69) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (+event.target.value >= 0 && +event.target.value <= 360 && !isNaN(+event.target.value)) {
                                                    if (event.target.value?.length <= 8) {
                                                        set_scenario_additional_orbits_details({
                                                            TA: event.target.value !== '' ? +event.target.value : ''
                                                        })
                                                    }
                                                } else {
                                                    toast.error("True Anomaly must be between 0 and 360", { toastId: '0' })
                                                }
                                            }}
                                            className="modalAnalysisInput"
                                            type="number"
                                            step='any'
                                            placeholder="degree"
                                        />
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                    <div className="modalFooter">
                        <button
                            className="modalFooterclose"
                            onClick={close}
                        >
                            Close
                        </button>
                        <button className="modalFooterapply" onClick={() => {
                            if (additional_orbit_details.name === '') {
                                toast.error("Object name is required", { toastId: '0' })
                            } else if (open === 'edit') {
                                submit_additional_orbit()
                            } else {
                                let check_same_orbit_name = additional_orbits?.find(orbit => orbit.name === additional_orbit_details.name)
                                if (check_same_orbit_name) {
                                    toast.error("Object name already exists.", { toastId: '0' })
                                } else {
                                    submit_additional_orbit()
                                }
                            }
                        }}>
                            Next
                        </button>
                    </div>
                </Fragment>
            }
        </div>
    )
}

export default AdditionalOrbitDetailsModal
