//@ts-nocheck
import { useEffect, useState } from 'react';
import { CloseIcon, DeleteIcon, DuplicateIcon, EditIcon, InfoIcon, MenuIcon, PrmoteMissionIcon, RenameIcon } from '../commonMMSvgs/MissionModelingSvgs';
import './actionsMenu.css'
import { ButtonToolbar, OverlayTrigger } from "react-bootstrap";

interface ActionsInputProps {
    show_menu: boolean,
    status?: string,
    component: string,
    handleInfo?: (data: string | number) => void,
    handleRename?: (data: string | number) => void,
    handleEdit?: (data: string | number) => void,
    handleDuplicate?: (data: string | number) => void,
    handleDelete?: (data: string | number) => void,
    handlePromote?: (data: string | number) => void
}


const ActionsMenu = ({ show_menu, status, component, handleInfo, handleRename, handleEdit, handleDuplicate, handleDelete, handlePromote }: ActionsInputProps) => {
    const [open_menu, set_open_menu] = useState<string | boolean>(false);

    useEffect(() => {
        if (!show_menu) {
            set_open_menu(false)
        }
    }, [show_menu])

    return (
        <ButtonToolbar onClick={() => { set_open_menu(show_menu) }}>
            <OverlayTrigger show={open_menu} placement="right"
                overlay={(
                    <div className={`menu_item_container ${show_menu && 'show_menu'} ${component === 'constellation' ? `` : ``}`}>
                        {/* {component === "constellation" && status !== "FAILED" && <div className='menu_item'
                            onClick={
                                handleInfo
                            }>
                            <InfoIcon />
                            <span>
                                Info
                            </span>
                        </div>} */}

                        <div className='menu_item'
                            onClick={
                                handleRename
                            }>
                            <RenameIcon />
                            <span>
                                Rename
                            </span>
                        </div>

                        <div className='menu_item'
                            onClick={
                                handleEdit
                            }>
                            <EditIcon />
                            <span>
                                Edit
                            </span>
                        </div>

                        {component === "constellation" && <div className='menu_item'
                            onClick={
                                handleDuplicate
                            }>
                            <DuplicateIcon />
                            <span>
                                Duplicate
                            </span>
                        </div>
                        }
                        <div className='menu_item'
                            onClick={
                                handleDelete
                            }>
                            <DeleteIcon />
                            <span>
                                Delete
                            </span>
                        </div>

                        {/* {component === "groundStation" && <div className='menu_item'
                            onClick={
                                handlePromote
                            }>
                            <PrmoteMissionIcon />
                            <span>
                                Promote to Mission
                            </span>
                        </div>} */}
                    </div>
                )}>
                <div className={`right_heder_icon_aoi ${show_menu && 'show'}`}
                >
                    <MenuIcon />
                </div>
            </OverlayTrigger>
        </ButtonToolbar>
    )
};

export default ActionsMenu; 