import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFeature from "./useFeature";
import mapboxgl from "mapbox-gl";
import { toast } from "react-toastify";
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import FeaturePopup from "./FeaturePopup";
import './FeatureMap.css'
import './FeaturePopup.css'
// import fileIcon from "../../MissionModeling/ImageSvgs/fileIcon_feature";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function ShapeFileMap({ setAllFeature, allFeature, fileName }) {
    const { mission_name, mission_id } = useParams();
    const navigate = useNavigate();
    const mapContainerRef = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);
    const [showPrompt, setShowPrompt] = useState(false);
    const [featureIndex, setFeatureIndex] = useState(null)
    const [promptPosition, setPromptPosition] = useState({ x: 0, y: 0 });
    const [featureType, setFeatureType] = useState("")
    const { postFeatureData } = useFeature()


    const updatePromptPositionForPolygon = (coordinates) => {
        const rectangle = coordinates.reduce((acc, coord) => {
            return {
                minX: Math.min(acc.minX, coord[0]),
                maxX: Math.max(acc.maxX, coord[0]),
                minY: Math.min(acc.minY, coord[1]),
                maxY: Math.max(acc.maxY, coord[1])
            };
        }, { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity });

        const x = rectangle.maxX + 10;
        const y = (rectangle.minY + rectangle.maxY) / 2;
        const point = map.current.project([x, y]);
        const canvasWidth = map.current.getCanvas().clientWidth;
        const canvasHeight = map.current.getCanvas().clientHeight;
        const promptWidth = 320;
        const promptHeight = 246; // Assuming a fixed height for the prompt
        // Check if there's enough space on the right
        if ((canvasWidth - point.x) < promptWidth) {
            const min_points = map.current.project([rectangle.minX, rectangle.minY]);
            // Not enough space on the right, try left
            if (min_points.x - promptWidth > 0) {
                point.x = min_points.x - promptWidth - 30; // Shift to the left
            } else {
                // Not enough space on either side, try above or below
                const midX = (rectangle.minX + rectangle.maxX) / 2;
                const midY = (rectangle.minY + rectangle.maxY) / 2;
                const centerPoint = map.current.project([midX, midY]);

                // Check if there's enough space above the polygon
                if (centerPoint.y - promptHeight > 0) {
                    point.x = centerPoint.x - promptWidth / 2;
                    point.y = centerPoint.y - promptHeight - 20;
                }
                // Check if there's enough space below the polygon
                else if (centerPoint.y + promptHeight + 20 < canvasHeight) {
                    point.x = centerPoint.x - promptWidth / 2;
                    point.y = centerPoint.y + 20;
                } else {
                    // Fallback: Place the prompt at the top-left corner if no space above/below
                    point.x = 20;
                    point.y = 20;
                }
            }
        }
        // Adjust for vertical boundaries (top and bottom)
        if (point.y < 50) {
            point.y = 50; // Set a minimum y-value to avoid going off-screen at the top
        } else if (point.y + promptHeight > canvasHeight) {
            point.y = canvasHeight - promptHeight - 20; // Prevent the prompt from going off the bottom of the screen
        }
        setPromptPosition({ x: point.x - 10, y: point.y + 60 });
    }

    const updatePromptPositionForPoint = (coordinates) => {
        const x = coordinates[0]; // Longitude
        const y = coordinates[1]; // Latitude
        const point = map.current.project([x, y]);
        const canvasWidth = map.current.getCanvas().clientWidth;
        const canvasHeight = map.current.getCanvas().clientHeight;
        const promptWidth = 320;
        const promptHeight = 246; // Assuming a fixed height for the prompt
        if ((canvasWidth - point.x) < promptWidth) { // Check if there's enough space on the right
            point.x = point.x - promptWidth - 50; // Shift to the left
        } else if (point.x < 50) {
            // If too close to the left edge
            point.x = 50; // Adjust to stay within left boundary
        }
        // Check for vertical boundaries (top and bottom)
        if (point.y < 50) {
            if (point.y < 0) {
                point.y = point.y + 600;
            } else {
                point.y = point.y + 47;
            }
            // Move down if too close to the top
        } else if (point.y > (canvasHeight - promptHeight - 20)) {
            point.y = canvasHeight - promptHeight - 20; // Adjust to stay within bottom boundary
        }
        setPromptPosition({ x: point.x + 20, y: point.y + 60 });
    }

    //transform data in api format
    const transformData = (details) => {
        let polyCount = 1;
        let pointCount = 1;
        const areaOfInterests = details.map((detail) => {
            // Compute `isPolygon` inside the map function
            const isPolygon = detail && (detail.type === 'MultiPolygon' || detail.type === 'Polygon');

            return {
                "name": detail.name !== "" ? detail.name : isPolygon ? `Poly ${polyCount++}` : `Point ${pointCount++}`,
                "type": isPolygon ? 'Geofence' : 'Target Track',
                "description": detail.description,
                "coordinates": {
                    "altitude": null,
                    "lat": isPolygon ? null : detail.coordinates[0],
                    "long": isPolygon ? null : detail.coordinates[1],
                    "minElevationAngle": null
                },
                "polygon": (isPolygon && allFeature.length === 1)
                    ? detail.coordinates
                    : (isPolygon ? sessionStorage.getItem("flatArrayFlag") === "true" ? detail.coordinates : detail.coordinates[0] : null)
            };
        });
        sessionStorage.setItem("flatArrayFlag", "false")

        return {
            areaOfInterests,
            "name": fileName,
            "type": "Collection"
        };
    };

    const ClosePopup = () => {
        setShowPrompt(false)
    }

    const handleInfo = (title, dec) => {
        const updatedFeature = {
            ...allFeature[featureIndex], // Keep all other data the same
            name: title,                 // Update the name
            description: dec            // Update the description
        };

        const updatedAllFeature = allFeature.map((feature, index) =>
            index === featureIndex ? updatedFeature : feature
        );

        setAllFeature(updatedAllFeature);

    }

    useEffect(() => {
        mapboxgl.accessToken =
            "pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw";
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/dark-v10",
            center: [0, 0],
            zoom: 1.8,
        });

    }, []);

    useEffect(() => {
        //mapping the features in shapefile
        if (allFeature?.length > 0) {
            allFeature?.map((item, index) => {
                if (map.current) {
                    map.current.on("load", () => {
                        // Create the bounding box polygon
                        const isPolygon = item?.type === 'MultiPolygon' || item?.type === 'Polygon';
                        const layerType = isPolygon ? 'fill' : 'circle';
                        const coordinates = isPolygon ? [item?.coordinates] : item?.coordinates;
                        map.current.addLayer({
                            id: "bounding-box-" + index,
                            type: layerType,
                            source: {
                                type: "geojson",
                                data: {
                                    type: "Feature",
                                    properties: {
                                        index,       // Include the index
                                        featureType: item?.type, // Include the type of the feature
                                        featureCoordinates: coordinates
                                    },
                                    geometry: {
                                        type: item?.type,
                                        coordinates: coordinates,
                                    },
                                },
                            },
                            layout: {},
                            paint: item?.type === 'Point' ? {
                                "circle-color": "#3AB2D0",
                                "circle-radius": 5, // Adjust radius as needed
                                "circle-opacity": 1,
                            } : {
                                "fill-color": "#3AB2D0",
                                "fill-opacity": 0.2,
                            },
                        });
                        map.current.addLayer({
                            id: "bounding-box-border-" + index,
                            type: isPolygon ? 'line' : 'circle',
                            source: {
                                type: "geojson",
                                data: {
                                    type: "Feature",
                                    properties: {
                                        index,       // Include the index
                                        featureType: item?.type, // Include the type of the feature
                                        featureCoordinates: coordinates
                                    },
                                    geometry: {
                                        type: item?.type,
                                        coordinates: coordinates,
                                    },
                                },
                            },
                            layout: item?.type === 'Point' ? {}
                                :
                                {
                                    "line-cap": "round",
                                    "line-join": "round"
                                },
                            paint: {
                                "line-color": "#3AB2D0", // Border color
                                "line-width": 2, // Border width
                            },
                        });

                        map.current.on('click', "bounding-box-" + index, (e) => {
                            if (e.features.length > 0) {
                                const feature = e.features[0]; // Get the clicked feature
                                const featureProperties = feature.properties; // Retrieve properties

                                // Access the index from properties
                                const clickedIndex = featureProperties.index;
                                setFeatureIndex(clickedIndex)
                                if (featureProperties.featureType === 'MultiPolygon' || featureProperties.featureType === 'Polygon') {
                                    setFeatureType("Polygon")
                                    if (sessionStorage.getItem("flatArrayFlag") === "true") {
                                        const flatCoordinates = coordinates.flat(1);

                                        updatePromptPositionForPolygon(flatCoordinates);
                                    } else {
                                        const flatCoordinates = coordinates.flat(1)[0];
                                        updatePromptPositionForPolygon(flatCoordinates);
                                    }
                                } else {
                                    setFeatureType("Point")
                                    updatePromptPositionForPoint(coordinates);
                                }

                                setShowPrompt(true);
                            }
                        });

                    });
                }
            })
        }
    }, [allFeature])


    return (
        <>
            <div className="w-100 h-100 FeatureMap position-absolute" ref={mapContainerRef} />
            <div className="d-flex listOfFeatures" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2px', width: '30px', height: '30px' }}>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.59368 0H1.9801C1.64725 0 1.32804 0.137209 1.09268 0.381442C0.857321 0.625676 0.725098 0.956927 0.725098 1.30233V14.6977C0.725098 15.0431 0.857321 15.3743 1.09268 15.6186C1.32804 15.8628 1.64725 16 1.9801 16H12.0201C12.353 16 12.6722 15.8628 12.9075 15.6186C13.1429 15.3743 13.2751 15.0431 13.2751 14.6977V13.0233V11.907V4.27907C13.275 4.13109 13.2182 3.98922 13.1174 3.88465L9.53163 0.163721C9.43086 0.0590194 9.29414 0.000130271 9.15154 0H4.66939H3.59368ZM12.1469 14.8292C12.1805 14.7943 12.1994 14.747 12.1994 14.6977V4.5107L8.92833 1.11628H1.9801C1.93255 1.11628 1.88695 1.13588 1.85333 1.17077C1.8197 1.20566 1.80081 1.25298 1.80081 1.30233V14.6977C1.80081 14.747 1.8197 14.7943 1.85333 14.8292C1.88695 14.8641 1.93255 14.8837 1.9801 14.8837H12.0201C12.0677 14.8837 12.1133 14.8641 12.1469 14.8292Z" fill="#F0F0F0" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53187 9.13885C9.63274 9.03418 9.6894 8.89221 9.6894 8.74419C9.6894 8.59616 9.63274 8.45419 9.53187 8.34952C9.431 8.24485 9.29419 8.18605 9.15154 8.18605H4.84868C4.70603 8.18605 4.56922 8.24485 4.46835 8.34952C4.36749 8.45419 4.31082 8.59616 4.31082 8.74419C4.31082 8.89221 4.36749 9.03418 4.46835 9.13885C4.56922 9.24352 4.70603 9.30233 4.84868 9.30233H9.15154C9.29419 9.30233 9.431 9.24352 9.53187 9.13885ZM9.53187 12.1156C9.63274 12.0109 9.6894 11.869 9.6894 11.7209C9.6894 11.5729 9.63274 11.4309 9.53187 11.3263C9.431 11.2216 9.29419 11.1628 9.15154 11.1628H4.84868C4.70603 11.1628 4.56922 11.2216 4.46835 11.3263C4.36749 11.4309 4.31082 11.5729 4.31082 11.7209C4.31082 11.869 4.36749 12.0109 4.46835 12.1156C4.56922 12.2203 4.70603 12.2791 4.84868 12.2791H9.15154C9.29419 12.2791 9.431 12.2203 9.53187 12.1156Z" fill="#F0F0F0" />
                        </svg>
                    </div>
                    <div className="ms-1 fileNameFont"> {fileName} </div>
                </div>
                <div>({allFeature.length})</div>
            </div >
            <div
                className="position-absolute"
                style={{
                    position: "absolute",
                    left: `${promptPosition.x + 170}px`,
                    top: `${promptPosition.y + 70}px`,
                    transform: "translate(-50%, -50%)",
                    zIndex: 999
                }}
            >
                {showPrompt &&
                    <FeaturePopup
                        close={() => ClosePopup()}
                        addTitle={(title, desc) => {
                            handleInfo(title, desc);
                            setShowPrompt(false);
                        }}
                        titleEdit={allFeature[featureIndex]?.name}
                        descriptionEdit={allFeature[featureIndex]?.description}
                        currentFeature={featureType}
                    />
                }
            </div>
            <button className="tickButton" onClick={() => {
                postFeatureData(mission_id, transformData(allFeature), "upload")
                navigate(`${process.env.PUBLIC_URL}/${mission_name}/_details/${mission_id}/Features`)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                    <path d="M1 5.3L5 9.5L13 0.5" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            <button className="closeButton" onClick={() => {
                // postFeatureData(mission_id, transformData(allFeature), "upload")
                navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/draw_feature`);
                sessionStorage.setItem("flatArrayFlag", "false")
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M1 1L13 13M13 1L1 13" stroke="#F0F0F0" stroke-width="1.125" stroke-linecap="round" />
                </svg>
            </button>
        </>
    )
}

export default ShapeFileMap;