
interface ModalHeaderProps {
  close: () => void;
  toggle_body: () => void;
}

const AdditionalOrbitModalHeader: React.FC<ModalHeaderProps> = ({ close, toggle_body }) => {
  const header = {
    title: "Additional Orbital Details",
    img: require("../../Images/ScenarioCreationIcon.svg").default,
  };

  return (
    <div className="modalHeader d-flex justify-content-between">
      <div className="d-flex gap-0">
        <div className="modalHeaderImg">
          <img
            src={header.img}
            className="active-img"
          />
        </div>
        <span>{header.title}</span>
      </div>
      <div className="d-flex gap-0">
        <button
          className="modalCloseBtn"
          onClick={close}
        >
          <img src={require("../../Images/CloseButtonWhite.svg").default} />
        </button>
        <button
          className="modalCloseBtn modalMinimizeBtn"
          onClick={toggle_body}
        >
          <img src={require("../../Images/MinimizeButtonWhite.svg").default} />
        </button>
      </div>
    </div>
  )
}

export default AdditionalOrbitModalHeader

