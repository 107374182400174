//@ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { ActiveGroundStation, AddIcon, CloseIcon, DeleteIcon, EditIcon, MenuIcon, MissionIcon, RenameIcon } from '../../../commonMMSvgs/MissionModelingSvgs';
import './groundStationCreationTree.css'
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';
import { useEffect, useState } from 'react';
import ActionsMenu from '../../../commonComponents/ActionsMenu';
import ModalInput from '../../../commonComponents/ModalInput';
import * as mmActions from '../../../../../store/MissionModellingSlice.js'
import { gs_visualization_presentation } from './Utils';
import Dialog from '../../../../CommonComponents/Dialog';

const GroundStationCreationTree = ({ openedCreationModal, mm_viewer }) => {

    const dispatch = useDispatch()
    const params = useParams()

    const allOrbitData = useSelector(state => state.missionModelling.allOrbit)

    const { mission_id } = params;
    const selectedGroundStationForCoverage = useSelector(state => state.missionModelling.selectedGroundStationForCoverage)

    const allGroundStationList = useSelector(state => state.missionModelling.allGroundStationList)
    const allGlobalGsList = useSelector(state => state.missionModelling.allGlobalGsList)
    const [show_menu, set_show_menu] = useState<string | boolean>(false)

    const [gsList, setGsList] = useState([])

    const [selectedGroundStation, setSelectedGroundstation] = useState('')
    const [renameGSState, setRenameGSState] = useState({
        selectedGroundStationId: '',
        selectedGroundStationName: ''
    })

    const [showGroundStationDelete, setShowGroundStationDelete] = useState()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.ground_station_main_header')) {
                setSelectedGroundstation('');
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handlePromoteToMission = (groundStation) => {
    }

    const handleEditGs = (groundStation) => {
        dispatch(mmActions.setEditGroundStationObject(groundStation))
    }

    const handleDeleteGS = (groundStation) => {
        setShowGroundStationDelete(groundStation)
    }

    const handleRenameGs = (groundStation) => {
        setRenameGSState({
            selectedGroundStationId: groundStation?.groundStationId,
            selectedGroundStationName: groundStation?.groundStationName
        })
    }

    const handleRenameGSClose = () => {
        setRenameGSState({
            selectedGroundStationId: '',
            selectedGroundStationName: ''
        })
    }

    const handleSaveGSName = () => {
        dispatch(MissionModellingService.renameGroundStation(renameGSState.selectedGroundStationId, renameGSState.selectedGroundStationName, mission_id))
        setRenameGSState({
            selectedGroundStationId: '',
            selectedGroundStationName: ''
        })
    }

    const addGroundStationForCoverage = (groundStation) => {
        if (openedCreationModal.groundStationanlysisModal) {
            dispatch(mmActions.setSelectedGroundStationForCoverage(groundStation))
        }
    }


    const get_selected_gs_list = (groundstations) => {
        const list = groundstations.map(gs => gs?.['groundStationId'])
        return list
    }

    const handleRemoveGS = (groundstation) => {
        dispatch(mmActions.removeSelectedGroundStationForCoverage(groundstation))
    }

    useEffect(() => {
        let groundStationList = []
        allGlobalGsList?.map((gs, index) => {
            if (gs?.['owner-org'] !== 'System' && !gs?.['owner-org']?.['properties']?.['provider']) {
                groundStationList.push(gs)
            }
        })
        allGroundStationList?.map((gs, index) => {
            groundStationList.push(gs)
        })
        setGsList(groundStationList)
    }, [allGroundStationList, allGlobalGsList])

    useEffect(() => {
        if (gsList?.length > 0 && mm_viewer) {
            gs_visualization_presentation().init_all_gs(gsList, mm_viewer)
            gs_visualization_presentation().init_all_satellites(allOrbitData, mm_viewer, "Orbit_Object")
        }
    }, [gsList, mm_viewer])

    return (
        <>
            <div className='ground_station_container'>
                {
                    gsList?.map((groundStation, index) => {
                        return (
                            <>
                                <div className='ground_station_main_header'
                                    onMouseEnter={() => {
                                        set_show_menu(groundStation?.groundStationId)
                                    }}
                                    onMouseLeave={() => {
                                        set_show_menu(false)
                                    }}
                                >

                                    <div className='ground_station_left_panel'
                                        onClick={() => {
                                            addGroundStationForCoverage(groundStation)
                                            const selected_all_gs = get_selected_gs_list(selectedGroundStationForCoverage)
                                            const selected_gs = selected_all_gs?.length > 0 ? [...selected_all_gs, groundStation?.groundStationId] : [groundStation?.groundStationId]
                                            gs_visualization_presentation().selected_gs(selected_gs, mm_viewer)
                                        }}
                                    >
                                        <ActiveGroundStation />
                                        {renameGSState.selectedGroundStationId !== groundStation?.groundStationId ?
                                            <span>
                                                {groundStation.groundStationName}
                                            </span>
                                            :
                                            <ModalInput
                                                isRename={true}
                                                value={renameGSState.selectedGroundStationName}
                                                handleChange={(value) => {
                                                    if (value.length <= 64) {
                                                        setRenameGSState({
                                                            ...renameGSState,
                                                            selectedGroundStationName: value
                                                        })
                                                    }
                                                }}
                                                handleClose={handleRenameGSClose}
                                                handleSave={handleSaveGSName}
                                            />}
                                    </div>

                                    <ActionsMenu
                                        show_menu={show_menu === groundStation?.groundStationId ? true : false}
                                        component='groundStation'
                                        handleDelete={() => {
                                            handleDeleteGS(groundStation)
                                        }}
                                        handleRename={() => {
                                            handleRenameGs(groundStation)
                                        }}
                                        handlePromote={() => {
                                            handlePromoteToMission(groundStation)
                                        }}
                                        handleEdit={() => {
                                            handleEditGs(groundStation)
                                        }}
                                    />

                                </div>
                            </>
                        )
                    })
                }

            </div>

            <Dialog
                onClick={(event) => {
                    if (event) {
                        dispatch(MissionModellingService.deleteGroundStation(showGroundStationDelete.groundStationId, mission_id))
                        handleRemoveGS(showGroundStationDelete)
                        gs_visualization_presentation().remove_entity_by_id(showGroundStationDelete.groundStationId, 'GS_Object', mm_viewer)
                        setShowGroundStationDelete()
                    }
                    setShowGroundStationDelete()
                }}
                openModel={showGroundStationDelete}
                comment={`Do you want to delete ${showGroundStationDelete?.groundStationName}?`}
            />
        </>
    );
};

export default GroundStationCreationTree;
