//@ts-nocheck
import { useSelector } from 'react-redux';
import { AddIcon, NormalGroundStation } from '../../../commonMMSvgs/MissionModelingSvgs';
import './groundStationcreation.css'
import GroundStationCreationTree from './GroundStationCreationtree';
import { useEffect, useState } from 'react';
import { gs_visualization_presentation } from './Utils';

const GroundStationCreation = ({ openGroundStationCreationModal, openedCreationModal, mm_viewer }) => {
    const allGroundStationList = useSelector(state => state.missionModelling.allGroundStationList)
    const allGlobalGsList = useSelector(state => state.missionModelling.allGlobalGsList)

    const [gsList, setGsList] = useState([])

    useEffect(() => {
        let groundStationList = []
        allGlobalGsList?.map((gs, index) => {
            if (gs?.['owner-org'] !== 'System' && !gs?.['owner-org']?.['properties']?.['provider']) {
                groundStationList.push(gs)
            }
        })
        allGroundStationList?.map((gs, index) => {
            groundStationList.push(gs)
        })
        setGsList(groundStationList)
    }, [allGroundStationList, allGlobalGsList])

    return (
        <>
            {/* This is Header of GS section */}
            <div className='GroundStationCreationHeader'>
                <div className='creationState'>
                    <NormalGroundStation />
                    <span className='headerText'>
                        Ground Stations
                    </span>
                    <span className='headerSubText'>
                        {`(${(gsList && gsList?.length !== 0) ?
                            gsList?.length : 0})`}
                    </span>
                </div>
                <div className='addIcon' onClick={() => { openGroundStationCreationModal() }}>
                    <AddIcon />
                </div>
            </div>
            {/* This is Body of GS section */}
            <div className='groundStationCreationBody'>
                {/* This block is display when there is no ground station */}
                {allGroundStationList?.length === 0 && <div className='create_gs_alignment' onClick={() => { openGroundStationCreationModal() }}>
                    <AddIcon />
                    <span>Create Ground Station</span>
                </div>}

                {/* This block is displayed when there is some ground station which we can show into the gs list */}
                {allGroundStationList?.length > 0 && <GroundStationCreationTree openedCreationModal={openedCreationModal} mm_viewer={mm_viewer} />}
            </div>
        </>
    );
};

export default GroundStationCreation;
